<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg cursor-pointer w-72 mt-4 md:w-80 lg:w-96 bg-backfill h-16
      item-component
    `"
  >
    <div class="flex flex-row items-center justify-start w-2/3 px-3 py-2">
        <p class="pr-2 text-xs text-content">
            <moving :size="23" v-if="category === 'Moving'" class="text-blue-600" />
            <sale :size="23" v-if="category === 'Sale'" class="text-green-600" />
            <storage :size="23" v-if="category === 'Storage'" class="text-pink-600" />
            <junk :size="23" v-if="category === 'Junk'" class="text-red-600" />
            <donate :size="23" v-if="category === 'Donate'" class="text-purple-600" />
            <memory :size="23" v-if="category === 'Memory'" class="text-yellow-400" />
        </p>
        <p class="font-bold text-left text-primary">{{name}}</p>
    </div>

    <div
      class="w-1/3 h-full overflow-hidden bg-center bg-cover rounded-r-lg"
      :style="`background-image: url('${image}');`"
    ></div>

  </div>
</template>

<script>

import Moving from 'vue-material-design-icons/Truck.vue';
import Sale from 'vue-material-design-icons/Upload.vue';
import Storage from 'vue-material-design-icons/InboxArrowDown.vue';
import Junk from 'vue-material-design-icons/TrashCan.vue';
import Donate from 'vue-material-design-icons/TagHeart.vue';
import Memory from 'vue-material-design-icons/Star.vue';

export default {
  name: 'ItemComponent',
  components: {
    Moving,
    Sale,
    Storage,
    Junk,
    Donate,
    Memory,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
      validate: (val) => ['Moving', 'Sale', 'Storage', 'Junk', 'Donate', 'Memory'].includes(val),
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
