<template>
  <div
    @click="click"
    :class="{'animate-pulse border-2 rounded-md border-yellow-300':pulse}"
    class="`
      flex flex-row justify-center sm:justify-start
      phone:px-2 py-2 my-1 cursor-pointer w-full
      text-primary dark:text-secondary
      border-2 border-transparent
      right-page-action-button
    `"
  >
    <div>
      <settings-icon v-if="icon === 'settings'" />
      <items-view v-if="icon === 'items'" />
      <share-icon v-if="icon === 'share'" />
      <circle-plus v-if="icon === 'plus'" />
      <heart-icon v-if="icon === 'heart'" />
      <timeline v-if="icon === 'timeline'" />
    </div>

    <p
      class="hidden pl-4 font-semibold text-left sm:inline"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
import SettingsIcon from 'vue-material-design-icons/Cog.vue';
import ItemsView from 'vue-material-design-icons/ViewList.vue';
import ShareIcon from 'vue-material-design-icons/Share.vue';
import CirclePlus from 'vue-material-design-icons/PlusCircle.vue';
import HeartIcon from 'vue-material-design-icons/Heart.vue';
import Timeline from 'vue-material-design-icons/Timeline.vue';

export default {
  name: 'RightPageActionButton',
  components: {
    SettingsIcon,
    ItemsView,
    ShareIcon,
    CirclePlus,
    HeartIcon,
    Timeline,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
      validate: (val) => ['settings', 'items', 'share', 'plus', 'heart', 'timeline'].includes(val),
    },
    pulse: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
